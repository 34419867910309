@import "../../assets/styles/cssImports.scss";

.tabs {
  &__wrapper {
    position: relative;
    display: inline-flex;
    gap: rem(30);
    align-items: center;
    padding-bottom: rem(10);
    border-bottom: 1px solid $grey-16;

    @media (max-width: $mobile-7) {
      flex-wrap: wrap;
      gap: rem(20);
      .button {
        border-radius: 0;
      }
    }
  }
}
