.fb_iframe_widget_fluid_desktop {
    iframe {
      width: 100%  !important;;
    }
  }

  .fb_iframe_widget_lift {
    width:100% !important;;
  }

.facebookComment {
  margin-top:30px;

  .title {
    font-size:1.6rem;
  }
}