@import "../../../assets/styles/cssImports.scss";

.footer-bottom-border {
  border-top: 1px solid $grey-4;
  display: flex;
  justify-content: center;
  margin-top: rem(52);
  width: 100%;

  .language-changer {
    color: $white-1;
    .language-changer__language {
      opacity: 1;
    }
  }
  .footerLanguageChanger {
    .react-tiny-popover-container {
      margin-top: unset;
      margin-top: 15px;
      top: -16px !important;
      .language-changer-popover-arrow {
        top: 100% !important;
      }
      @media (max-width: 1400px) {
        left: -86px !important;
      }
    }
  }
}

.footer-bottom-wrapper {
  color: $white-1;
  display: grid;
  grid-auto-flow: column;
  font-size: rem(16);
  justify-content: space-between;
  margin: rem(45) 0 rem(45) 0;
  width: 80%;
  padding-right: rem(62);
  @media (max-width: $mobile-1) {
    padding-right: unset;
  }

  .footer-bottom-item {
    a {
      text-decoration: none;
      color: $blue-3;
    }
  }
}

.social-media {
  align-items: center;
  display: flex;
  gap: rem(13);
  &__icon-wrapper {
    display: flex;
    gap: rem(13);
  }
  &__icon {
    cursor: pointer;
  }
}

@media (max-width: 1400px) {
  .footer-bottom-wrapper {
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    row-gap: rem(30);
    column-gap: rem(30);
    justify-content: space-evenly;
  }
}

@media (max-width: 700px) {
  .footer-bottom-wrapper {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto;
    row-gap: rem(50);
    margin-left: rem(10);
  }
  .social-media {
    flex-direction: column;
    align-items: flex-start;
    gap: rem(20);

    &__icon-wrapper {
      gap: rem(25);
    }
  }
}
