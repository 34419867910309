@import '../../../assets/styles/cssImports.scss';

.pr-radio-circle-wrapper-comp {
    width: rem(25);
    min-width: rem(25);
    height: rem(25);
    border-radius: 100%;
    border: 2px solid $grey-23;
    margin-right: rem(15);
    padding: rem(5);
    @include trainsition-1;
    cursor: pointer;
}


.pr-radio-circle-comp {
  background-color: $red-1;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  @include trainsition-1;
}

.pr-radio-cirlce-comp-active {
    border-color: $red-1;
    color: $red-1;

    .pr-radio-circle-wrapper-comp {
        border-color: $red-1;
    }
}