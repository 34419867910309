@import "../../assets/styles/cssImports.scss";

.action-btn {
  background-color: $white-1;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  height: rem(62);
  width: rem(62);
  @include box-shadow-2($shadow-black-1);

  svg {
    width: rem(24);
  }
}
