@import "../../../../assets/styles/cssImports.scss";

.reset-password-initial {
  &-wrapper {
    margin-bottom: rem(40);
    margin-left:auto;
    margin-right:auto;
    position: relative;

    @media (max-width: $mobile-1) {
      max-width: rem(320);
      .text-input{
	  width:60%;
	  }
    }
  
    &-input {
      width: 100%;
      font-size: rem(16);
      background-color: $white-1;

      ::placeholder {
        font-size: rem(16);
        color: $grey-4;
        opacity: 0.5;

        @media (max-width: 300px) {
          opacity: 0;
        }
      }
    }

    &-get-code {
      position: absolute;
      top: rem(14);
      right: rem(14);
      font-size: rem(14);
      font-weight: 500;
      color: $red-1;
      cursor: pointer;
    }
    &-get-code-sent {
      opacity: 0.5;
      color: $grey-4;
    }

    &-code-status {
      font-size: rem(14);
      color: $grey-4;
      opacity: 50%;
      font-weight: 500;
      margin-top: rem(10);
      margin-left: rem(25);
    }
  }

  &-button {
    color: $white-1;
    background-color: $red-1;
    transition: 0.3s ease-out;

    @media (max-width: $mobile-1) {
      max-width: rem(320);
    }

    &:hover {
      background-color: $red-2;
    }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
  &-button-inActive {
    opacity: 0.5;
  }
}
