@import "../../assets/styles/cssImports.scss";

.breadcrumb {
  display: flex;
  font-size: rem(14);
  font-weight: 500;
  white-space: nowrap;
  padding-right: rem(60);
  flex-wrap: wrap;

  &::after {
    content: '';
    display: inline-block;
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    height: 100%;
    width: rem(60);
    position: absolute;
    top: 0;
    right: 0;

    @media (max-width: 720px) {
      overflow-x: scroll;
      background: transparent;
    }
  }

  &-wrapper {
    display: flex;
  }

  &__link {
    color: $grey-4;
    text-decoration: none;
    cursor: pointer;

    &:last-child {
      cursor: auto;
      pointer-events: none;
    }
  }

  &__divider {
    margin: 0 rem(8);
  }

  &::-webkit-scrollbar {
    display: none;
  }
}