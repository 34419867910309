@import "../../../assets//styles/cssImports.scss";

.scrollbar-hidden {
  overflow: hidden;
}

.navigation-scrolled {
  // position: relative;

  .navigation-animation {
    height: 100%;
    animation: background-top-bottom 600ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  }
}

.navigation-animation {
  top: 0;
  left: 0;
  width: 100%;
  @include box-shadow-2($shadow-black-1);
  background-color: white;
  position: absolute;
  z-index: 0;
  animation: background-bottom-top 600ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
}

.navigation {
  position: fixed;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  min-height: rem(100);
  align-items: center;
  width: 100%;
  padding-left: rem(69);
  padding-right: rem(69);
  transition: all 600ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;

  @media (max-width: 1500px) {
    padding: 0 rem(30);
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(30);
    z-index: 1000;

    @media (max-width: 1400px) {
      gap: rem(15);
    }

    &-auth {
      display: flex;
      align-items: center;
      gap: rem(25);

      @media (max-width: 1400px) {
        gap: rem(15);
        
      }

      a {
        text-decoration: none;
        color: #373737;
        opacity: 70%;
        font-size: rem(16);

        &:hover {
          color: $red-1;
        }
        @media (max-width: $desktop-1) {
          font-size: rem(14);
        }
      }
    }
  }

  &__left {
    cursor: pointer;
    max-width: rem(140);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
  }

 
  
  &__center {
    display: flex;
    z-index: 10;
    gap: rem(25);

    // @media (max-width: 1280px){

    //   display: none;
    // }


}
    @media (max-width: 1400px) {
      gap: rem(15);
    }
  
}

@keyframes background-top-bottom {
  from {
    height: 0;
  }

  to {
    height: 100%;
  }
}

@keyframes background-bottom-top {
  from {
    height: 100%;
  }

  to {
    height: 0;
  }
}

.navigation-scrolling {
  opacity: 0.8;
}

.navigation__center-item--disabled{
  color:#373737;
}