@import '../../../assets/styles/cssImports.scss';

.pr-filter-button{
    outline: none;
    border-radius: 20px;
    background-color: transparent;
    border:1px solid $grey-9;
    color: $grey-4;
    font-size: rem(18);
    padding: rem(8) rem(26) rem(8) rem(26);
    cursor: pointer;

    &__active{
        border: 1px solid transparent;
        background-color: $grey-2;
    }
}