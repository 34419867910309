@import "../../../assets/styles/cssImports.scss";

.clinic-info ul {
  padding-left: rem(25);
  margin-top: rem(64);
  font-size: rem(20);
  line-height: rem(24);

  @media (max-width: $desktop-1) {
    font-size: rem(18);
  }
  @media (max-width: $mobile-1) {
    margin-top: rem(30);
  }

  li:not(:first-child) {
    padding-top: rem(32);
    @media (max-width: $desktop-1) {
      padding-top: rem(25);
    }
  }
}
