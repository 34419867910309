@import "../../../../assets/styles/cssImports.scss";

.inner-accord {
  font-size: rem(16);
  text-decoration: none;
  color: $grey-4;
  &__head{
    display: flex;
    align-items: center;
    width:100%;
    padding: rem(20) 0;
    border-bottom: 1px solid $grey-10;
    &-container{
      width:100%;
      display: flex;
      align-items:center;
    }
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: rem(29);
    color: $grey-4;
    width: rem(22);
    height: rem(22);
    margin-right: rem(16);
    &--nospace{
      margin:0;
    }
    &--rotated{
      transform: rotate(-180deg);
    }
  }
  &__body{
    padding-left:rem(38);
    padding-bottom:rem(22);
  }
}
