@import "../../assets/styles/cssImports.scss";

.page-title {
  color: $grey-4;
  display: flex;
  font-size: rem(42);
  font-family: 'Helvetica Bold';
  font-weight: bold;
  justify-content: center;
  line-height: rem(51);
  width: 100%;
  margin-top: rem(180);

  @media (max-width: $desktop-1) {
    font-size: rem(34);
  }

  @media (max-width: $mobile-1) {
    margin-top: rem(100);
    font-size: rem(24);
  }
}
