@import "../../../../assets/styles/cssImports.scss";

.navigation-mobile-link {
  font-size: rem(16);
  text-decoration: none;
  display: flex;
  align-items: center;
  color: $grey-4;
  padding: rem(20) 0;
  border-bottom: 1px solid $grey-10;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: rem(29);
    color: $grey-4;
    width: rem(22);
    height: rem(22);
    margin-right: rem(16);
  }
}
