@import "../../../assets/styles/cssImports.scss";

.clinic-icons {
  
  display: flex;
  gap: rem(120);
  flex-wrap: nowrap;

  overflow-wrap: break-word;
  justify-content: space-between;
  width: 100%;

  @media (max-width: $mobile-1) {
    gap: rem(50);
    width: unset;
  }
}
