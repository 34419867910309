@import "../../../assets/styles/cssImports.scss";

.auth-form {
  width: rem(574);
  padding: rem(55) rem(54);
  margin-left: rem(220);
  margin-top: rem(61);
  background-color: $white-1;
  border-radius: 31px;

  @media (max-width: $mobile-1) {
    width: 100%;
    margin-left: 0;
    padding: rem(27);
    padding-top: rem(55);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius:0;
  }

  @media (max-width: 500px) {
    form{
      width:100%;
    }
  }

  &__header-text {
    font-size: rem(20);
    color: $grey-4;
    margin-bottom: rem(40);
  }

  &__question {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: rem(40);
    gap: rem(15);

    @media (max-width: 400px) {
      flex-direction: column;
    }

    @media (max-width: $mobile-1) {
      justify-content: start;
    }

    &-text {
      text-align: center;
      color: $grey-4;
      opacity: 60%;
      font-size: rem(16);
      font-weight: 500;
    }
  }

  &__footer-text {
    color: $red-1;
    background: transparent;
    font-size: rem(16);
    font-weight: rem(500);
    padding: 0;
  }
}
