@import "../../assets/styles/cssImports.scss";

.checkbox__remember {
  display: flex;
  align-items: center;
  margin-top: rem(40);

  .checkbox-wrapper {
    display: block;
    position: relative;
    padding-left: rem(45);
    font-size: rem(16);
    color:$grey-4;
    user-select: none;
  }

  .checkbox-wrapper input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkbox-new {
    position: absolute;
    top: -3px;
    left: 0;
    height: rem(30);
    width: rem(30);
    // background-color: $red-1;
    border: 1px solid $grey-5;
    border-radius: 7px;
    cursor: pointer;
  }

  .checkbox-new:after {
    content: "";
    position: absolute;
    display: none;
  }

  .checkbox-wrapper input:checked ~ .checkbox-new:after {
    display: block;
  }
  .checkbox-wrapper input:checked ~ .checkbox-new {
    background-color: $red-1;
  }

  .checkbox-wrapper .checkbox-new:after {
    left: 10px;
    top: 7px;
    width: 5px;
    height: 10px;
    border: solid white;

    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
}
