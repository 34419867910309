/** standard colors **/
$white-1: #ffffff;
$red-1: #ef3125;
$red-2: #d92f24;
$black-1: #000000;
$black-2: #1d1c1c;
$grey-1: #6b6b6e;
$grey-2: #f8f9fc;
$grey-3: #efefef;
$grey-4: #303134;
$grey-5: #d2d2d2;
$grey-6: #979899;

$grey-7: #707073;
$grey-8: #dcdfe5;
$grey-9: #f4f4f4;

$grey-10: #eaebee;
$grey-11: #eef0f6;
$grey-12: #323232;
$grey-13: #303134;
$grey-14: #f4f4f4;
$grey-15: #3e3e3e;
$grey-16: #e5e5e5;

$grey-17: #b4bcc9;
$grey-18: #fbfcfd;
$grey-19: #7b7b7b;
$grey-20: #707070;
$grey-21: #f8f9fc;
$grey-22: #7b7b7b;
$grey-23: #939698;
$grey-25: #f5f5f5;
$grey-26: #f5f5f5;

$yellow-1: #ffe622;
$yellow-2: #dec60a;
$yellow-3: #fbbd08;
$yellow-4: #f8dec2;

$blue-1: #7d93ab66;
$blue-2: #294481;
$blue-3: #36889c;

$green-1: #21ba45;
$green-2: #36C057;

/* shadow colors */
$shadow-black-1: #0000000f;
$shadow-black-2: #0000000d;

$rgba-grey-1: rgba(112, 112, 112, 0.1);
