@import "../../assets/styles/cssImports.scss";

.service-item {
  width: 100%;
  height: rem(131);
  display: flex;
  align-items: center;
  gap: rem(24);
  border-radius: 20px;
  background-color: $grey-2;
  color: $grey-4;
  padding: 0 rem(40);

  transition: all 0.1s ease-out;

  @media (max-width: $desktop-1) {
    padding: 0 rem(15);
  }

  @media (max-width: $mobile-1) {
    padding: 0 rem(10);
  }

  @media (max-width: $mobile-7) {
    gap: rem(20);
  }

  &:hover,
  &-active {
    color: $red-1;
    cursor: pointer;

    .service-item__icon {
      border: 1px solid $red-1;
    }

    .service-item__title {
      color: $red-1;
      opacity: 1;
    }
  }

  &__icon {
    width: rem(70);
    height: rem(70);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white-1;
    border-radius: 100%;
    flex: none;

    svg {
      width: 24px;
      height: 24px;
    }

    @media (max-width: $desktop-1) {
      width: rem(60);
      height: rem(60);
    }
  }

  &__title {
    font-size: rem(16);
    text-align: left;
    line-height: rem(22);
    opacity: 70%;
    color: $grey-4;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    white-space: pre-wrap;
    word-break: break-word;

    @media (max-width: $desktop-1) {
      font-size: rem(14);
    }
  }
}

.services__items-container {
  .service-item {
    width: 100%;
  }
}