@import '../../assets/styles/cssImports.scss';

.pr-icon-wrapper {
    width: rem(50);
    height: rem(50);
    background-color: $grey-2;
    border-radius: 100%;
    font-size: rem(20);
    color: $grey-4;
    display: flex;
    justify-content: center;
    align-items: center;

    svg{
        fill: $grey-4;
        width: rem(20);
        height: rem(20);
    }
}