@import "assets/styles/cssImports.scss";

.search-results {
  cursor: pointer;

  &-pagination {
    margin-bottom: rem(180);
    @include pagination-list-page;
  }

  &__quantity {
    font-size: rem(18);
    color: $grey-4;
    opacity: 70%;
    margin-bottom: rem(20);
  }

  &__wrapper {
    a {
      text-decoration: none;
      color: $grey-4;
    }
  }
}
