@font-face {
  font-family: "Helvetica";
  src: url("./FiraGO-UltraLight.ttf") format("truetype");
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica";
  src: url("./FiraGO-Thin.ttf") format("truetype");
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Light";
  src: url("./FiraGO-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Roman";
  src: url("./FiraGO-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Medium";
  src: url("./FiraGO-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Bold";
  src: url("./FiraGO-Bold.ttf") format("truetype");
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Bolder";
  src: url("./FiraGO-ExtraBold.ttf") format("truetype");
  font-weight: bolder;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Black";
  src: url("./FiraGO-ExtraBold.ttf") format("truetype");
  font-weight: 1000;
  font-display: swap;
}
