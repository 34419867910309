@import "../../../../assets/styles/cssImports.scss";

.navigation-mobile-content {
  position: fixed;
  overflow: auto;
  top: 0;
  background-color: $grey-9;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  animation: content-fade-out 0.2s ease-in-out forwards;
}

.navigation-mobile-content-active {
  padding-top: rem(80);
  height: 100%;
  animation: content-fade-in 0.2s ease-in-out forwards;
  z-index: 2004;
}

@keyframes content-fade-in {
  from {
    height: 0;
    padding-top: rem(0);
  }
  to {
    height: 100%;
    padding-top: rem(70);
  }
}

@keyframes content-fade-out {
  from {
    height: 100%;
    padding-top: rem(70);
  }

  to {
    padding-top: 0;
    height: 0;
  }
}
