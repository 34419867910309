@import "../../assets/styles/cssImports.scss";

.private-layout {
  width: 100%;
  display: flex;
  background-color: $grey-2;
  overflow: hidden;
}

.private-layout-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: rem(131) rem(69) rem(54) rem(69);
  column-gap: rem(30);
  max-height: calc(100vh - 205px);

  @media (max-width:$desktop-1) {
    margin: rem(100) rem(69) rem(30) rem(69);
    max-height: calc(100vh - rem(130));
  }

  @media (max-width:1400px) {
    margin: rem(100) rem(30) rem(30) rem(30);
    max-height: calc(100vh - rem(130));
  }

  @media (max-width: 1200px) {
    margin: rem(100) 0;
    // max-height: calc(100vh - 100px);
    max-height: fit-content;
  }

  @media (max-width: $mobile-7) {
    margin-bottom: rem(190);
  }
}

.private-layout-content {
  width: 74.5%;
  height: 100%;
}

@media (max-width: 1200px) {
  .private-layout-content {
    width: 100%;
    height: 100%;
  }
}