@import "assets/styles/cssImports.scss";

.search-results {
  &-not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: rem(204);

    &__image {
      margin-bottom: rem(60);
      img {
        display: inherit;
        margin: 0 auto;
        max-width: 100%;
        object-fit: contain;
      }
    }

    &__text {
      font-size: rem(24);
      color: $grey-4;
    }
  }
}
