$table-border: 1px solid $grey-16;

/** animation mixins **/
@mixin trainsition-1($duration: 0.2s) {
  transition: $duration all ease-in-out;
}

/** box-shadow mixins **/
@mixin box-shadow-1 {
  box-shadow: 0 12px 40px #00000029;
}

@mixin box-shadow-2($color) {
  box-shadow: 0 3px 6px $color;
}

@mixin box-shadow-3 {
  box-shadow: 0px 3px 6px #00000008;
}

/** button mixins **/

@mixin button-1 {
  max-width: rem(272);
  color: $white-1;
  background-color: $grey-4;
}

/** dynamic image **/
@mixin render-image-1($height: 237) {
  width: 100%;
  height: rem($height);
  overflow: hidden;
  display: flex;
  justify-content: center;
}
@mixin render-profile-image-1() {
  width: rem(76);
  height: rem(72);
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

@mixin render-profile-image-2() {
  width: rem(50);
  height: rem(50);
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex: 0 0 rem(50);
  justify-content: center;
}

@mixin render-profile-image-3() {
  width: rem(91);
  height: rem(91);
  border-radius: 11px;
  border: 1px solid $rgba-grey-1;
  overflow: hidden;
  display: flex;
  flex: 0 0 rem(91);
  justify-content: center;

  @media (max-width: 1200px) {
    width: rem(60);
    flex: none;
    height: rem(60);
  }
}

@mixin render-icon-image-1() {
  width: rem(50);
  height: rem(50);
  align-items: center;
  border-radius: 15px;
  overflow: hidden;
  text-align: center;
  display: flex;
  flex: 0 0 rem(50);
  font-size: rem(20);
  justify-content: center;
}

@mixin render-filter-button-group-1 {
  width: 100%;
  display: flex;
  gap: rem(13);
  margin-top: rem(40);
}

/** grids **/
@mixin grid-1($amount) {
  display: grid;
  grid-column-gap: rem(30);
  grid-row-gap: rem(30);
  grid-template-columns: repeat($amount, minmax(92%/$amount, 1fr));
  margin-top: rem(30);
  width: 100%;

  @media (max-width: 1520px) {
    grid-template-columns: repeat($amount - 1, minmax(100%/$amount - 1, 1fr));
  }

  @media (max-width: 900px) {
    grid-template-columns: 100%;
  }
}

@mixin pagination-list-page {
  margin-top: rem(90);

  @media (max-width: $mobile-1) {
    margin-top: rem(60);
    margin-bottom: rem(150);
  }
  @media (max-width: $mobile-3) {
    width: 100%;
    margin-top: rem(60);
    margin-bottom: rem(150);
  }
}

@mixin card-1() {
  border-radius: 31px;
  background-color: $white-1;
  // padding: rem(38);
  padding: rem(38) 0;
  background-color: $white-1;
  @include box-shadow-3;
}
