@import "../../assets/styles/cssImports.scss";

.range-picker {
  input {
    display: flex;
    text-align: center;
    border-radius: 24px;
    border: solid 1px $grey-5;
    color: $grey-4;
    font-weight: 500;
    font-size: 1rem;
    outline: none !important;
    padding: rem(14) rem(17) rem(14) rem(25);

    &:focus {
      border: solid 1px $grey-4 !important;
    }
  }
}

.react-datepicker__day {
  font-size: 1rem;
  color: $grey-4;
}

.react-datepicker__day--in-range {
  background-color: $red-1;

  &:hover {
    background-color: $red-1;
    color: aliceblue;
  }
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
  background-color: $grey-4;
  color: aliceblue;
}

.react-datepicker__day--keyboard-selected {
  background-color: $red-1;
  color: $white-1;

  &:hover {
    background-color: $red-1;
  }
}

.react-datepicker__day-name {
  font-size: 1rem;
  font-weight: bold;
  color: $grey-4;
  width: fit-content;
  background-color: #F8F9FC;
  border-radius: 9px;
}

.react-datepicker__header {
  border: none;
  border-radius: 12px;
  background-color: transparent;
}
.react-datepicker {
  border-radius: 12px;
  border: 1px solid $grey-5;
  overflow: hidden;
}

.react-datepicker__navigation {
  top: 20px;
}

.react-datepicker__current-month {
  margin-top: rem(20);
  margin-bottom: rem(20);
}