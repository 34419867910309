@import "../../assets/styles/cssImports.scss";

.dark {
  background-color: $black-1;
  border: 1px solid $grey-4;
  color: $white-1;
}

.light {
  background-color: $white-1;
  border: 1px solid $grey-5;
  color: $black-1;
}

.icon-with-border {
  align-items: center;
  border-radius: 100%;
  display: flex;
  justify-content: center;

  &.small {
    height: rem(30);
    width: rem(30);
  }

  &.medium {
    height: rem(40);
    width: rem(40);
  }

  &.large {
    height: rem(50);
    width: rem(50);
  }
}
