@import "../../assets/styles/cssImports.scss";

.general {
  &__control {
    padding-left: 12 !important;
    padding-right: 12 !important;
  }
  &__placeholder {
    font-size: rem(16);
    color: $grey-4 !important;
    font-weight: 400 !important;
    opacity: 0.5;
  }
  &__single-value {
    font-weight: 400;
  }
}
