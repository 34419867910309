@import "../../assets/styles/cssImports.scss";

.accordion {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: $white-1;
  padding: 0 rem(24);
  border-radius: 31px;

  .accordion-item {
    padding: rem(24) 0;
    color: $grey-4;
    font-size: rem(18);
    line-height: rem(22);
    font-family: 'Helvetica Bold';
    font-weight: bold;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &__button {
      border: none;
      background-color: $white-1;
      width: rem(24);
      height: rem(24);
      cursor: pointer;
    }

    &__title {
      display: flex;
      align-items: center;
      gap: rem(8);
      word-break: break-word;
    }
  }
  &__content {
    padding-bottom: rem(24);
  }
}
