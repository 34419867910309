@import "../../../../assets/styles/cssImports.scss";

.complete-reset-password {
  display: flex;
  flex-direction: column;
  gap: rem(30);

  &__input {
    @media (max-width: $mobile-1) {
      max-width: rem(320);
    }
  }
}

.new-password {
  &-button {
    color: $white-1;
    background-color: $red-1;
    transition: 0.3s ease-out;

    &:hover {
      background-color: $red-2;
    }

    @media (max-width: $mobile-1) {
      max-width: rem(320);
    }
  }
}
