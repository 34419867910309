@import "../../assets/styles/cssImports.scss";

.title {
  color: $grey-4;
  font-size: rem(42);
  font-family: 'Helvetica Bold';
  font-weight: bold;

  @media (max-width: 1200px) {
    font-size: rem(30);
  }

  @media (max-width: $mobile-1) {
    font-size: rem(24);
  }
}
