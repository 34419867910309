@import '../../../assets/styles/cssImports.scss';

.pr-radio-secondary{
    display: flex;
    align-items: center;
    cursor: pointer;

    &__circle{
        margin-right: rem(10);
    }

    &__text{
        font-size: rem(17);
        color: $grey-4;
        font-weight: 500;
    }
}