@import "assets/styles/cssImports.scss";

.password {
    &-input {
        width: 100%;
        font-size: rem(16);
        background-color: $white-1;

        ::placeholder {
            font-size: rem(16);
            color: $grey-4;
            opacity: 0.5;
        }
    }

    &-wrapper {
        position: relative;
    }

    &-toggle {
        position: absolute;
        top: 12px;
        right: 14px;
        color: $grey-5;
        opacity: 0.5;
        font-size: rem(24);
        cursor: pointer;
    }
}