@import "../../assets/styles/cssImports.scss";

.circle-icon-wrapper {
  width: rem(96);
  height: rem(96);
  align-items: center;
  background-color: $white-1;
  border-radius: 100%;
  display: flex;
  font-size: rem(28);
  justify-content: center;
  @include box-shadow-2($shadow-black-2);

  svg {
    width: 28px;
    height: 28px;
  }
}
