@import "../../assets/styles/cssImports.scss";

.radio {
  margin: 0.5rem;
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    + .radio-label {
      margin-right: rem(40);
      font-size: rem(16);
      color: $grey-4;

      &:before {
        content: "";
        background: $white-1;
        border-radius: 100%;
        border: 1px solid $grey-5;
        display: inline-block;
        width: rem(30);
        height: rem(30);
        position: relative;
        top: -0.2em;
        margin-right: 1em;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: $red-1;
          box-shadow: inset 0 0 0 10px $white-1;
          border: 1px solid $red-1;
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: $grey-5;
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px $grey-5;
          border-color: darken($grey-5, 25%);
          background: darken($grey-5, 25%);
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}
