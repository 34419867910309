@import "../../../assets/styles/cssImports.scss";

.langauge-changer-popover-item {
  display: flex;
  gap: rem(11);
  align-items: center;

  &:not(:last-child) {
    margin-bottom: rem(18);
  }

  &__icon {
    width: rem(22);
    height: rem(22);
    border-radius: 100%;
    flex: none;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    color: $grey-4;
    font-size: rem(14);
  }
}
