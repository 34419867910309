@import "../../../../assets/styles/cssImports.scss";

.contact-info {
  color: $white-1;
  font-size: rem(16);
  &__item {
    gap: rem(8);
    align-items: center;
    display: flex;
    padding-top: rem(20);

    a {
      color: $white-1;
    }
  }
  &__item span {
    width: rem(224);
    line-height: rem(22);
  }
}
