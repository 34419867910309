@import "../../../assets/styles/cssImports.scss";

.modal-close {
  right: rem(-30);
  top: rem(-30);
  position: absolute;

  @media (max-width: $mobile-1) {
    right: rem(10);
    top: rem(-20);
    width: rem(50);
    height: rem(50);
  }

  @media (max-width: $mobile-5) {
    width: rem(40);
    height: rem(40);
  }

  svg {
    font-size: rem(20);
  }
}
