@import '../../../assets/styles/cssImports.scss';

.promotion-present{
    width: rem(52);
    height: rem(52);
    background-color: $red-1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top:rem(26);
    left: rem(26);
    border-radius: 100%;
    svg{
        width:rem(20);
        height: rem(20);
        color: $white-1;
    }
}

.promotion-percent{
    width: rem(121);
    height: rem(52);
    background-color:$red-1;
    font-size: rem(24);
    color: $white-1;
    position: absolute;
    top:rem(26);
    left: 0;
    display: flex;
    font-family: 'Helvetica Bold';
    font-weight: bold;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 26px;
    border-bottom-right-radius: 26px;
}