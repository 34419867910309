@import '../../assets/styles/cssImports.scss';

.textarea-wrapper{
  width: 100%;
  padding: rem(14) rem(25) rem(14) rem(25);
  color: $grey-4;
  @include trainsition-1;
  border-radius: 24px;
  display: flex;
  background-color: $white-1;
  flex-direction: column;
  border: solid 1px $grey-5;
}
.textarea{
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  color: $grey-4;
}

.textarea-bottom{
  margin-top: rem(25);
}
.textarea-top {
  margin-bottom: rem(25);
}