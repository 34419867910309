@import "../../assets/styles/cssImports.scss";

.navitem {
  color: #373737;
  font-size: rem(16);
  line-height: rem(20);
  text-decoration: none;
  transition: all 0.2s ease-out;
  
  &.active {
    color:#ef3125;
    & + .navitem-expand {
      color: $red-1;
    }
  }

  &-wrapper {
    display: inline-flex;
    align-items: center;
    position: relative;

    &:hover {
      > .navitem,
      .navitem-expand {
        color: $red-1;
      }
    }
  }

  &-expand {
    color: #373737;
    cursor: pointer;
    transition: all 0.2s ease-out;
  }

  &-popover-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;

    .navitem-wrapper {
      width: 100%;

      .navitem {
        width: 100%;
        padding: rem(10) rem(26);
        size: rem(16);
        opacity: 90;
      }

      :hover {
        background-color: $grey-2;
      }
    }
  }

  @media (max-width: $desktop-1) {
    font-size: rem(14);
  }
}

.active {
  color: $red-1;
}

.react-tiny-popover-container {
  cursor: unset;
  padding: rem(21) 0;
  // margin-top: rem(20);
  background-color: $white-1;
  border-radius: 20px;
  width: rem(230);
  display: flex;
  z-index:999999;
  // position: absolute;
  // left: -2%;

 

  .popover-arrow-container {
    padding: 0 !important;
    width: 100%;

    .navitem-popover-arrow {
      display:none;
      top: -18px !important;
      left: 54% !important;
      color: $white-1;
    }
  }
}
