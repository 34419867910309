@import "../../assets/styles/cssImports.scss";

.language-changer {
  display: flex;
  align-items: center;
  gap: rem(3);
  color: $grey-4;
  cursor: pointer;

  .react-tiny-popover-container {
    padding: rem(12);
    padding-right: rem(25);
    width: rem(94);
    justify-content: center;

    > div {
      padding: 0 !important;
    }

    .language-changer-popover-arrow {
      top: rem(-13) !important;
      color: $white-1;
    }
  }

  &__icon {
    font-size: rem(20);
  }

  &__language {
    font-size: rem(16);
    font-weight: 500;
    opacity: 70%;

    @media (max-width: $desktop-1) {
      font-size: rem(14);
    }
  }

  &__expand {
    font-size: rem(18);
    cursor: pointer;
  }
}
