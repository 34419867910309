@import "../../../assets/styles/cssImports.scss";

.accordion__item {
  width: 100%;
  min-height: rem(70);
  padding: rem(25) rem(34);
  border-radius: 31px;
  font-size: rem(18);
  font-family: "Helvetica Bold";
  font-weight: bold;
  color: $grey-4;
  background: $grey-2;
  margin-bottom: rem(40);
  // word-break: break-all;
  overflow-wrap: break-word;

  &-heading {
    &-button {
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;

      svg {
        width: rem(20);
        height: rem(20);
        margin-right: rem(15);
      }

      &-question {
        width: 70%;
      }

      &:before {
        content: "";
        position: absolute;
        right: 0;
        height: 7px;
        width: 7px;
        border-bottom: 2px solid currentColor;
        border-right: 2px solid currentColor;
        transform: rotate(-45deg);
      }

      &[aria-expanded="true"]::before {
        transform: rotate(45deg);
      }
    }
  }

  &-panel {
    margin-top: rem(20);
    &-text {
      line-height: 40px !important;
      margin-left: rem(34);
      li {
        :hover {
          color: #ef3125;
        }
      }
    }
  }
  &__branches {
    display: flex;
    justify-content: space-between;
    gap: 8%;
    max-width: 90%;
    &__section {
      display: flex;
      gap: rem(11);
      font-size: rem(18);
      line-height: rem(22);
      max-width: 30%;
      @media (max-width: $desktop-1) {
        font-size: rem(14);
        line-height: rem(17.5);
      }
      &_heading {
        min-width: fit-content;
        opacity: 60%;
      }
      &_time {
        max-width: 140px;
        div {
          white-space: nowrap;
        }
      }
    }
    &__section:last-child {
      max-width: none;
    }
    @media (max-width: 1300px) {
      max-width: 100%;
      &__section {
        flex-direction: column;
        max-width: none;
      }
    }
    @media (max-width: $mobile-4) {
      flex-direction: column;
      gap: rem(31);
      &__section {
        &_time {
          max-width: unset;
          div {
            margin-bottom: rem(10);
          }
        }
      }
    }
  }
}

.expanded {
  color: $red-1;
}
