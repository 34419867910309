@import "../../../assets/styles/cssImports.scss";

.sidebar-mobile {
  width: 97%;
  height: rem(73);
  border: 1px solid $grey-2;
  border-radius: 20px;
  z-index: 1000;
  padding: rem(21) rem(15);
  position: fixed;
  bottom: rem(20);
  left: rem(10);
  background-color: $white-1;
  box-shadow: 0px 6px 12px #0000000d;
  display: flex;
  align-items: center;
  gap: rem(20);

  .pr-sidebar-link {
    padding: 0;
    flex-direction: column;
    text-align: center;
    gap: rem(11);

    &.active {
      background-color: unset;
    }

    &__icon {
      @media (min-width: 800px) {
        svg {
          width: rem(25);
          height: rem(25);
        }
      }

      margin-right: 0;
    }

    &__text {
      @media (min-width: 800px) {
        font-size: rem(15);
      }

      font-size: rem(11);
    }
  }

  &__button{
    &-fluid{
      width:100%;
    }
  }
}
