@import "../../assets/styles/cssImports.scss";

.text-input-wrapper {
  align-items: center;
  display: flex;
  border-radius: 24px;
  border: solid 1px $grey-5;
  color: $grey-4;
  padding: rem(14) rem(17) rem(14) rem(25);
  width: 100%;
  position: relative;

  @include trainsition-1;

  .search-icon {
    color: $grey-5;
  }

  .text-input {
    cursor:pointer;
    border: none;
    color: $black-2;
    outline: none;
    width: 100%;
    font-size: 1rem;
    &::placeholder{
      color:$grey-6;
    }
  }

  .text-input-icon {
    height: rem(20);
    width: rem(20);
  }
}

.text-input-wrapper:hover {
  border: solid 1px $grey-4;
}

::placeholder {
  color: $grey-6;
  opacity: 1;
}

/** handlin error state here **/
.text-input-error,
.text-input-error:hover {
  border: solid 1px $red-1;

  .text-input-icon {
    color: $red-1;
  }
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-40 {
  margin-bottom: rem(40);
}

.text-input-error-message {
  color: $red-1;
  font-size: rem(14);
  margin-left: rem(25);
  margin-top: rem(8);
}

.search-input-close-icon {
  display: none;
}
