@import "../../assets/styles/cssImports.scss";

.accordion {
  margin-bottom: rem(180);
  width: 100%;

  @media (max-width: $mobile-1) {
    padding: 0;
    margin-bottom: 0;
  }
}
