@import "../../../assets/styles/cssImports.scss";

.modal-button {
  width: rem(60);
  height: rem(60);
  border-radius: 100%;
  background-color: white;
  border: 1px solid #7d93ab66;
  @include box-shadow-2($shadow-black-2);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000000;

  @media (max-width: $mobile-1) {
    width: rem(50);
    height: rem(50);
  }

  @media (max-width: $mobile-5) {
    width: rem(40);
    height: rem(40);
  }

  svg {
    fill: $grey-4;
  }
}
