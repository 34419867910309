@import "../../assets/styles/cssImports.scss";

.transparent-card {
  align-items: center;
  display: flex;
  height: rem(118);
  justify-content: center;
  position: relative;
  text-align: center;
  width: rem(272);
  cursor: pointer;
  background-color: rgba($black-1, 0.9);
  border-radius: 21px;

  &__text {
    color: $white-1;
    font-size: rem(24);
    line-height: rem(29);
    z-index: 5;

    @media (max-width: $desktop-1) {
      font-size: rem(20);
      line-height: rem(24.16);
    }

    @media (max-width: $mobile-1) {
      .card {
        font-size: rem(16);
      }
    }
  }
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .transparent-card {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: rgba($black-1, 0.15);  
  }
}