@import '../../assets/styles/cssImports.scss';

.navProfile {
    &_top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 10px;
        cursor: pointer;
        img {
            width: 50px;
            height: 45px;
            object-fit: cover;
            border-radius: 11px;
            border: 1px solid $grey-5;
        }
        &Right {
            display: flex;
            align-items: center;
            column-gap: 7px;
            .userName {
                text-transform: capitalize;
                white-space: break-spaces;
                color: $grey-13;
                opacity: 0.7;
                font-size: rem(16);
                line-height: rem(20);
                font-weight: normal;

                @media (max-width: $desktop-1) {
                    font-size: rem(14);
                }
            }
            svg {
                path {
                    color: $grey-4;
                }
            }
        }
    }
    .react-tiny-popover-container {
        padding: unset;
        display: block;
        width: rem(217);
        .navProfile-popover-arrow {
            right: rem(20) ;
            left: unset !important;
            top: rem(-13) !important;
            color: $white-1;
          }
        nav {
            width: 100%;
            .pr-sidebar-link, .pr-sidebar-button {
                padding: 17px 15px;
            }
        }
    }
}
