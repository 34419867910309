@import "../../../../assets/styles/cssImports.scss";

.icon-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 180px;
  height: fit-content;
  gap: rem(20);
  font-family: 'Helvetica Bold';
  font-weight: bold;
  @media (max-width: $desktop-1) {
    gap: rem(18);
  }
  .circle-icon-wrapper {
    @media (max-width: $desktop-1) {
      width: rem(60);
      height: rem(60);
      svg {
        width: rem(24);
        height: rem(24);
      }
    }
  }

  &__number {
    font-size: rem(42);
    @media (max-width: $desktop-1) {
      font-size: rem(26);
    }
    @media (max-width: $mobile-1) {
      font-size: rem(24);
    }
  }
  &__text {
    text-align: center;
    font-size: rem(24);
    line-height: rem(29);
    @media (max-width: $desktop-1) {
      font-size: rem(20);
    }
    @media (max-width: $mobile-1) {
      font-size: rem(18);
    }
  }
}
