@import "../../../assets/styles/cssImports.scss";

.rounded-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: rem(50);
  height: rem(50);
  background: $white-1;
  color: $grey-4;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  font-size: rem(20);
}
