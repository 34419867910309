@import "../../../../assets/styles/cssImports.scss";

.pr-sidebar-button {
  width: 100%;
  display: flex;
  padding: rem(16) rem(16) rem(16) rem(21);
  color: $grey-4;
  font-size: rem(18);
  align-items: center;
  border: none;
  background-color: transparent;
  font-weight: 500;
  border-radius: 24px;
  @include trainsition-1;
  cursor: pointer;

  &:hover {
    color: $red-1;
  }

  &__icon {
    margin-right: rem(12);
    display: flex;
    font-family: 'Helvetica Bold';
    font-weight: bold;
    align-items: center;
    height: 100%;
  }
  @media (max-width: $desktop-1) {
    font-size: rem(16);
  }
}
