@import "../../../assets/styles/cssImports.scss";

.sidebar-desktop {
  width: 24%;
  max-width: rem(400);
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  @include card-1;
  padding: rem(20) 0;

  &__body {
    width: 90%;
    margin: 0 auto;
  }

  &__footer {
    width: 90%;
    margin: 0 auto;
  }

  a {
    text-decoration: none;
  }
}

.sidebar-desktop__notification {
  position: relative;
  display: flex;
  align-items: center;

  &-badge {
    position: absolute;
    bottom: rem(10);
    left: rem(7);
    width: rem(17);
    height: rem(17);
    border-radius: 100%;
    background-color: $red-1;
    border: 1px solid $grey-2;
    font-size: rem(11);
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white-1;
  }
}