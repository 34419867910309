@import "../../../assets/styles/cssImports.scss";

.rows {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: rem(40);
  width: 100%;
}

.footer-links {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1400px) {
 .rows {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  row-gap: rem(70);
  column-gap: rem(30);
  justify-content: space-evenly;
 }
}

@media (max-width: 700px) {
 .rows {
  display: grid;
  grid-template-columns: auto ;
  grid-template-rows: auto auto auto auto;
  row-gap: rem(50);
  margin-left: rem(10);
 }
}
