@import "../../../../assets/styles/cssImports.scss";

.pr-sidebar-link {
  width: 100%;
  display: flex;
  padding: rem(16) rem(16) rem(16) rem(21);
  color: $grey-4;
  opacity: 80%;
  font-size: rem(18);
  align-items: center;
  border-radius: 24px;

  &.active {
    color: $red-1;
    background-color: $grey-2;
  }

  &__icon {
    margin-right: rem(12);
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__text {
  }

  @media (max-width: $desktop-1) {
    font-size: rem(16);
  }
}
