@import "../../../assets/styles/cssImports.scss";

.filter-button {
  max-width: rem(200) !important;
  width: fit-content;
  padding: rem(14) rem(35);
  display: flex;
  justify-content: center;
  align-items: center;
  color: $grey-4;
  margin-right: 8px;
  background-color: $grey-2;
  &:last-child {
    margin-right: 0;
  }
  &__active {
    @include button-1;
  }
}
