@import "../../assets/styles/cssImports.scss";

.pr-button {
  border-radius: 24px;
  padding: rem(14) rem(25) rem(14) rem(25);
  background-color: transparent;
  border: none;
  font-size: rem(16);
  transition: all 0.3s ease-out;
  cursor: pointer;
  @media (max-width: $desktop-1) {
    font-size: rem(14);
  }

  &__primary {
    color: $white-1;
    background-color: $red-1;

    &:hover {
      background-color: $red-2;
    }
  }
  &__secondary {
    color: $white-1;
    background-color: $grey-4;

    &:hover {
      background-color: $black-2;
    }
  }
  &__third{
    color: $grey-4;
    background-color: $grey-21;
  }
}
