@import "../../assets/styles/cssImports.scss";

.pr-card {
  @include card-1;
  padding: rem(30);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  @media (max-width: $desktop-1) {
    padding: rem(20) rem(30);
  }

  @media (max-width: $mobile-7) {
    height: unset !important;
    min-height: 100%;
  }

  &__wrapper {
    height: 100%;
    overflow-y: scroll;
    width: 100%;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: rem(28);
    flex-wrap: wrap;

    @media (max-width: $desktop-1) {
      padding-bottom: rem(15);

      &--icon {
        font-size: rem(10);
        width: rem(40);
        height: rem(40);
      }
    }

    &--back {
      font-size: rem(25);
      margin-right: rem(20);
      color: $grey-4;
      cursor: pointer;
    }

    &--left {
      display: flex;
      align-items: center;
    }
  }

  &__header--title {
    font-size: rem(24);
    font-family: "Helvetica Bold";
    font-weight: bold;
    color: $grey-4;
    margin-left: rem(21);

    @media (max-width: $desktop-1) {
      font-size: rem(18);
    }
  }
}

.pr-card-border {
  .pr-card__header {
    border-bottom: 1px solid $rgba-grey-1;
  }
}
