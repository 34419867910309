@import "../../assets/styles/cssImports.scss";

.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: $black-1;
  opacity: 0.5;
  top: 0;
  left: 0;
  z-index: 1000000;
}

.modal {
  width: 95%;
  height: 90%;
  border-radius: 21px;
  background-color: $white-1;
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
  padding: rem(50);
  z-index: 100000000;
  
  ul, ol {
    padding-inline-start: 30px;
  }

  ::-webkit-scrollbar {
    // display: none;
  }
  @media(max-width:$mobile-5){
    padding: rem(28);
    width:94%;
  }
}
