@import "../../../assets/styles/cssImports.scss";

.pr-radio-primary {
  display: flex;
  align-items: center;
  border-radius: 24px;
  justify-content: center;
  background-color: $grey-21;
  outline: none;
  border: none;
  padding: rem(9) rem(21) rem(9) rem(9);
  font-size: rem(17);
  color: $grey-4;
  cursor: pointer;
  border: 1px solid transparent;
  @include trainsition-1;

  .react-tiny-popover-container {
    padding: rem(15) rem(32);
    top: rem(-13) !important;
    box-shadow: 0px 2px 10px #1013230f;
    border-radius: 13px;
    background-color: $grey-4 !important;
    color: $white-1;
    margin-top: 0;

    > div {
      padding: 0 !important;
    }

    .pr-radio-popover-arrow {
      left: rem(20) !important;
      bottom: rem(-12) !important;
      color: $grey-4;
    }
  }
  &-active {
    border: 1px solid $red-1;
    @include trainsition-1;
  }
}
