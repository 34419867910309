@import '../../assets/styles/cssImports.scss';



.pagination{
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;

    &__content{
        align-items: center;
        border: 1px solid $grey-5;
        border-radius: 33px;
        display: flex;
        padding: rem(14) rem(32);

        @media(max-width:$mobile-3){
           padding: rem(14) rem(10);
        }
        @media(max-width:$mobile-7) {
            justify-content: center;
             width: 100%;
     }

         .pagination-arrow{
            cursor: pointer;
         }

         .pagination-arrow:first-child{
                margin-right: rem(25);
            @media(max-width:$mobile-3) {
                  margin-right: rem(10);
            }
         }

        .pagination-arrow:last-child {
            margin-left: rem(25);
              @media(max-width:$mobile-3) {
                  margin-left: rem(10);
                 }
            }
    }
}