@import "../../../assets/styles/cssImports.scss";

.auth-header {
  width: 100%;
  padding: rem(46) rem(69) 0 rem(69);
  display: flex;
  justify-content: space-between;

  &__language {
    display: flex;
    align-items: center;
  }
}
