@import '../../../assets/styles/cssImports.scss';




.pagination-item{
    align-items: center;
    border-radius: 100%;
    color: $grey-1;
    cursor: pointer;
    display: flex;
    font-size: rem(16);
    height: 30px;
    justify-content: center;
    width: 30px;

    flex:0 0 30px;

    &.active{
        background-color: $red-1;
        color: white;
        @include trainsition-1
    }
}

