@import "../../assets/styles/cssImports.scss";

.footer-wrapper {
  position: relative;
  align-items: center;
  background-color: $black-1;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 999;
}

.footer-rows {
  margin-top: rem(52);
  width: 80%;
}
