@import "../../assets/styles/cssImports.scss";

.button {
  width: 100%;
  border: none;
  border-radius: 24px;
  color: $white-1;
  cursor: pointer;
  font-size: rem(16);
  padding: rem(14) 2.1%;
  &:disabled{
    cursor: unset;
  }
}

.viber-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #655cab!important; /* Viber purple */
  color: #fff; /* White text for contrast */
  border: none;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.viber-button:hover {
  background-color: #5e4bcf; /* Slightly darker purple */
}

.viber-icon {
  display: inline-block;
}

.w-auto {
  width:auto;
}

.with-hover {
  background-color: $grey-2;
  color: $grey-4;
  @include trainsition-1;
}

.with-hover:hover {
  background-color: $red-1;
  color: $white-1;
}
