@import "./fonts/fontFace.scss";
@import "./cssImports.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
button {
  font-family: "Helvetica Medium", sans-serif, Georgia;
  font-weight: 500;
  font-style: normal;
}

::-webkit-scrollbar {
  background-color: white;
  width: 10px;
}
::-webkit-scrollbar-track {
  background-color: white;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(68, 68, 68, 0.7);
  border-radius: 16px;
  border: 1px solid #fff;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 1px solid #f4f4f4;
}

.fixed_body {
  overflow: hidden;
  block-size: 100%;
}

.list-page-pagination {
  margin-top: rem(90);
}

a {
  color: $grey-4;
  text-decoration: none;
}

.cx-side-button-group {
  z-index: 100000 !important;
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  transform: none !important;
  border-radius: 50% !important;
  div {
    box-shadow: none !important;
  }
  .cx-webchat-chat-button {
    box-shadow: none !important;
    padding: 0 !important;
  }
  .cx-side-button {
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cx-widget.cx-webchat-chat-button .cx-icon {
    width: unset !important;
    height: unset !important;
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cx-chat-button-label {
    display: none !important;
  }
}
.cx-theme-light:not(.cx-common-container) {
  background-color: transparent !important;
}
.cx-common-container .cx-buttons-window-control button svg {
  width: 100% !important;
}
.cx-widget.cx-theme-light .cx-svg-icon-tone1 {
  fill: $grey-4 !important;
  path {
    stroke-width: 5px !important;
  }
}

.ktt10-btn {
  bottom: 50px !important;
  right: 70px !important;
  width: 3.875rem !important;
  height: 3.875rem !important;
}

@media screen and (max-width: $mobile-1) {
  .ktt10-btn {
    bottom: 110px !important;
    right: 30px !important;
    width: 50px !important;
    height: 50px !important;
  }
}
