@import "../../assets/styles/cssImports.scss";

.info-item {
  display: flex;
  align-items: center;
  gap: rem(20);
  @media (max-width: $mobile-7) {
    align-items: flex-start;
  }
  &-content {
    display: flex;
    align-items: center;
    gap: rem(20);

    @media (max-width: 600px) {
      flex-direction: column;
      align-items: unset;
      gap: rem(5);
    }
  }

  &__icon {
    flex: none;
    width: rem(60) !important;
    height: rem(60) !important;
    border: none;
    background-color: $grey-2;
    font-size: rem(20);
    color: $grey-4;
  }

  &__category {
    color: $grey-4;
    opacity: 0.7;
    font-weight: 500;
    font-size: rem(20);
    @media (max-width: $mobile-7) {
      margin-bottom: rem(5);
    }
  }

  &__text {
    color: $grey-4;
    font-weight: 500;
    font-size: rem(20);
  }
}
