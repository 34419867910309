@import "../../assets/styles/cssImports.scss";

.search-input-wrapper {
  width: rem(18);
  height: rem(37);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .react-tiny-popover-container {
    width: rem(438);
    max-height: rem(375);
    overflow-y: auto !important;
    padding: rem(20) rem(9);
    margin-top: 0;
    top: rem(8) !important;
    left: rem(-210) !important;

    &::-webkit-scrollbar {
      width: rem(2);
      height: rem(2);
    }

    &::-webkit-scrollbar-thumb {
      width: rem(2);
      cursor: pointer;
      background-color: $grey-4;
    }

    &::-webkit-scrollbar-track {
      margin-top: rem(10);
      margin-bottom: rem(10);
      background-color: $grey-2;
    }

    &::-webkit-scrollbar-corner {
      display: none;
    }
  }
}

.search-input-closed {
  color: $grey-4;
  cursor: pointer;
  font-size: rem(17);
}
.search-input-default {
  pointer-events: none;
  opacity: 0;
}

.search-input-close {
  pointer-events: none;
  animation: animationClose 1s forwards;
}

.search-input {
  right: 0;
  z-index: 100000;
  position: absolute;
  background-color: white;
  transition: all ease-in-out;
}

.search-input-visible {
  opacity: 1;
  pointer-events: all;
  width: 438px;
  animation: animation 1s forwards;
  background-color: $white-1;

  input {
    width: inherit;
    font-family: inherit;
    font-weight: 500;
  }

  &:focus-within {
    border-color: $red-1;
  }

  ~ .search-input-close-icon {
    display: block;
  }

  .search-input-close-icon {
    display: block;
    z-index: 1000000;
    color: $grey-4;
    right: rem(16);
    font-size: rem(19);
    cursor: pointer;
  }
}

@keyframes animation {
  from {
    width: 0;
  }

  to {
    width: 238px;
    animation: animation 1s forwards;
  }
}

@keyframes animation {
  from {
    width: 0;
  }

  to {
    width: 238px;
  }
}

@keyframes animationClose {
  from {
    width: 238px;
  }

  to {
    opacity: 0;
    width: 0;
  }
}

.search-results {
  &-all {
    padding: 0;
    color: $red-2;
    width: fit-content;
    opacity: 90%;
    font-size: rem(14);
    font-family: inherit;
    background-color: transparent;
    font-weight: 500;
    position: absolute;
    top: 19px;
    left: 375px;
  }

  &-input {
    &-not-found {
      width: 100%;
      margin-bottom: rem(0) !important;
      padding: rem(60) 0;
      

      .search-results-not-found__image {
        width: 42%;
        margin-bottom: rem(29);
        

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .search-results-not-found__text {
        font-size: rem(20);
        font-weight: 500;
        font-family: Helvetica;
      }
    }

    .search-results {
      &__wrapper {
        width: 100%;
      }

      &__quantity {
        font-size: rem(14);
        padding: 0 rem(11);
        margin-bottom: rem(5);
      }

      &-item {
        width: 100%;
        margin-bottom: 0;
        padding: rem(15) rem(11);

        &:hover {
          cursor: pointer;
          background-color: $grey-2;
          .search-results-item__info-content, .search-results-item__info-category {
            color: $red-1;
          }
        }

        &__image {
          flex: none;
          width: rem(50);
          height: rem(50);
          margin-right: rem(14);
        }

        &__info {
          &-content {
            font-size: rem(16);
            margin-bottom: rem(4);
          }

          &-category {
            font-size: rem(14);
          }
        }
      }
    }
  }

  to {
    opacity: 0;
    width: 0;
  }
}

@media (max-width: $desktop-1) {
  .search-results-not-found__image {
    width: 80%;
  }
  .search-results-not-found__text {
    font-size: rem(18) !important;
  }
}