@import "../../../assets/styles/cssImports.scss";

.tabs {
  &__button {
    position: relative;
    font-size: rem(20);
    color: $grey-4;
    opacity: 70%;
    width: unset;
    padding: 0;
    background-color: transparent;
    transition: all 0.3s ease-out;
    @media (max-width: $desktop-1) {
      font-size: rem(18);
    }
    @media (max-width: $mobile-7) {
      font-size: rem(16);
    }

    &:hover {
      color: $red-2;
      opacity: 1;
    }

    &-active {
      color: $red-1;
      opacity: 1;

      &::before {
        content: "";
        position: absolute;
        top: rem(36);
        width: 100%;
        height: rem(1.5);
        background-color: $red-1;
        @media (max-width: $desktop-1) {
          top: rem(31);
        }

        @media (max-width: $mobile-7) {
          top: rem(28);
        }
      }
    }
  }
}
