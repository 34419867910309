@import "../../assets/styles/cssImports.scss";

.slider-container {
  width: 100%;
  height: rem(700);

  .swiper {
    width: 100%;
    height: inherit;
    margin-left: auto;
    margin-right: auto;
    border-radius: 21px;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  }

  .swiper-slide > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-2 {
    margin-top: rem(32);

    .swiper-slide {
      width: 25% !important;
      height: rem(200);
      margin-right: rem(20) !important;
      cursor: pointer;
      border-radius: 20px;

      img {
        object-fit: cover;
        border-radius: 20px;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: $grey-4;
    width: rem(50);
    height: rem(50);
    background-color: $white-1;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 rem(25);
    
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 16px;
    font-family: 'Helvetica Bold';
    font-weight: bold;
  }

  .swiper-button-next:after {
    margin-left: rem(3);
  }

  .swiper-button-prev:after {
    margin-right: rem(3);
  }

  .mySwiper .swiper-slide-thumb-active {
    opacity: 1;
  }

  .swiper-pagination-progressbar {
    background-color: $grey-5;
  }

  .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background-color: $red-1;
  }

  .swiper-pagination-progressbar {
    top: auto !important;
    bottom: 0;
  }
}
