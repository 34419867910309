@import '../../assets/styles/cssImports.scss';


.label{
    padding: rem(14) rem(35);
    background-color: $white-1;
    color: $grey-4;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 6px #00000029;
    cursor: pointer;
    max-width: rem(143);
}