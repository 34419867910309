@import "../../../assets/styles/cssImports.scss";


.scrollbar-hidden {
  overflow: hidden;
}

.navigation-scrolled {

  .navigation-animation {
    height: 100%;
    animation: background-top-bottom 600ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  }
  .navigation-mobile__left {
    position: relative;
    z-index: 2;
  }
  .navigation-mobile__right {
    position: relative;
    z-index: 2;
  }
}

.navigation-animation {
  top: 0;
  left: 0;
  width: 100%;
  @include box-shadow-2($shadow-black-1);
  background-color: white;
  position: absolute;
  z-index: 0;
  animation: background-bottom-top 600ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
}

@keyframes background-top-bottom {
  from {
    height: 0;
  }

  to {
    height: 100%;
  }
}

@keyframes background-bottom-top {
  from {
    height: 100%;
  }

  to {
    height: 0;
  }
}

.navigation-scrolling {
  opacity: 0.8;
}

.navigation-mobile {
  width: 100%;
  position: fixed;
  overflow: auto;
  overflow-x: hidden;
  top: 0;
  z-index: 2005;
  padding: rem(20) rem(30);
  display: flex;
  align-items: center;
  justify-content: space-between;
  // box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);

  .navigation-mobile__left {
    svg {
      max-width: rem(100);
    }
  }

  &-personal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(30);
    background-color: $white-1;
    margin-top: rem(40);
    margin-bottom: rem(30);

    &__info {
      display: flex;
      align-items: center;
      gap: rem(15);

      &-avatar {
        width: rem(45);
        height: rem(45);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
          border: 1px solid $grey-5;
        }
      }

      &-name {
        color: $grey-4;
        font-size: rem(16);
        text-transform: capitalize;
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      gap: rem(22);

      &-settings {
        color: $grey-4;
        font-family: "Helvetica Medium";
        font-weight: medium;
        font-size: rem(25);
      }
      &-logout {
        color: $grey-4;
        font-family: "Helvetica Medium";
        font-weight: medium;
        font-size: rem(25);
      }
    }
  }

  &-is-auth {
    background-color: $white-1 !important;
    border-radius: 30px;
    padding: rem(30);
    margin: 0 0 rem(30) 0 !important;

    .accordion__content {
      margin-top: rem(10);
      padding-bottom: 0;
    }

    .accordion-item {
      padding: 0 !important;
      &__title {
        .navigation-mobile-header {
          margin-bottom: rem(0);
        }
      }
    }
  }

  &-header {
    font-size: rem(18);
    font-family: "Helvetica Medium";
    font-weight: medium;
    background-color: inherit;
    color: $grey-4;
    margin-bottom: rem(10);
  }

  &-personal-pages {
    margin-bottom: rem(30);
    margin: rem(8) rem(28);
  }

  &-main-pages {
    margin: rem(8) rem(28);
  }

  .wrapper-open {
    position: fixed !important;
    left: 0;
    width: 100%;

    svg {
      // display: none;
    }
  }

  &__left {
    img {
      max-width: rem(100);
    }
  }

  &__right {
    display: flex;
    align-items: center;
    gap: rem(27);
    // @media (max-width: 1280){
    //   display: flex;
    // }

    .close {
      width: rem(20);
      cursor: pointer;
    }

    .search-input-wrapper {
      .search-input-visible {
        width: calc(100% - 20px);
        position: fixed;
        left: 10px;

        animation: unset;
      }

      .search-input-visible ~ .react-tiny-popover-container {
        width: 100%;
        top: rem(10) !important;
        left: 0 !important;

        .search-results-all {
          left: unset;
          right: rem(18);
        }

        .search-results-not-found {
          padding: rem(30);
          text-align: center;

          .search-results-not-found__image {
            width: rem(120);
            height: rem(120);
          }
          .search-results-not-found__text {
            font-size: rem(16);
          }
        }
      }

      .search-input-close {
        opacity: 0;
        animation: unset;
      }
    }

    svg {
      font-size: rem(20);
      cursor: pointer;
    }

    svg:first-child {
      margin-left: 0;
    }
  }
}
.navigation-mobile-links {
  display: flex;
  flex-direction: column;
}

@keyframes animationMobileClose {
  from {
    width: 100%;
    opacity: 1;
  }

  to {
    opacity: 0;
    width: 0;
  }
}

@keyframes animationMobileOpen {
  from {
    width: 0;
    opacity: 0;
  }

  to {
    opacity: 1;
    max-width: 100%;
  }
}

.navMob1 {
  background-color: white;
}
