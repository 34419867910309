@import "assets/styles/cssImports.scss";

.search-results-item {
  width: 100%;
  display: flex;
  margin-bottom: rem(30);

  @media (max-width: 300px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__image {
    width: rem(187);
    height: rem(187);
    margin-right: rem(40);
    flex: none;

    @media (max-width: $mobile-7) {
      width: rem(110);
      height: rem(110);
      margin-right: rem(24);
    }

    @media (max-width: 300px) {
      margin-right: 0;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 15px;
      object-fit: cover;
    }
  }

  &__info {
    @media (max-width: 300px) {
      margin-top: rem(10);
      text-align: center;
    }

    &-content {
      font-size: rem(24);
      font-family: "Helvetica Medium";
      font-weight: 400;
      color: $grey-4;
      margin-bottom: rem(20);
      word-break: break-word;
      overflow-wrap: break-word;
      flex-wrap: wrap;

      @media (max-width: $mobile-7) {
        font-size: rem(18);
        margin-bottom: rem(10);
      }
    }

    &-category {
      font-size: rem(20);
      color: $grey-4;
      opacity: 0.8;
      @media (max-width: $mobile-7) {
        font-size: rem(16);
      }
    }
  }
}

.search-results__breadcrumb.breadcrumb::after {
  display: none;
}
