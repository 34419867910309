@import "../../../assets/styles/cssImports.scss";

.modal__prev {
  left: rem(-30);
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);

  svg {
    width: rem(30);
    height: rem(30);
  }
}

.modal__next {
  right: rem(-30);
}

@media (max-width: $mobile-1) {
  .modal__next {
    right: rem(-15);
  }

  .modal__prev {
    left: rem(-15);
  }
}
